.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media(max-height:500px) {
		min-height: 500px;
	}

	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			overflow: hidden;

			@media (max-height: 600px) {
				/*min-height: 680px;*/

			}

			.img {
				object-fit: cover;
				/* object-position: 100% 70%; */
				width: 100%;
				height: 100%;
			}

			.img1 {}

			.img2 {}

			.img3 {
				object-position: 100% 80%;
			}

			.img4 {
				object-position: 100% 80%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}


	.hero-header {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media(max-width:992px) AND (min-height: 701px) {
			bottom: 45vh;
		}

		@media(max-width:992px) AND (max-height: 700px) {
			top: 5vh;
		}

		@media(min-width:999px) AND (min-height: 501px) {
			bottom: 29vh;
		}

		@media(min-width:992px) AND (max-height: 500px) {
			top: 30vh;
		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			.h1 {
				font-family: var(--hero-header-font-family);
				font-size: 5vw;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 80px;
				}

				@media (max-width: 1140px) {
					font-size: 57px;
				}

				@media (max-width: 992px) {
					font-size: 4rem;
					line-height: 4rem;
				}

				@media (max-width: 576px) {
					font-size: 3rem;
					line-height: 3rem;
				}

				@media (max-height: 600px) {
					font-size: 2.75rem;
					line-height: 2.75rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}
	}
}