.unit-image-gallery-mobile-v1 {
	.visible-print {
		display: none !important;
	}

	.unitGallery {
		a {
			display: none;
			padding-right: 8px;
			height: 160px;
			cursor: pointer;

			@media (max-width: 580px) {
				height: 130px;
			}

			@media (max-width: 460px) {
				height: 100px;
			}

			&:nth-child(1),
			&:nth-child(2) {
				float: left;
				display: block;
				overflow: hidden;
				width: 50%;
				height: 230px;

				@media (max-width: 580px) {
					height: 180px;
				}

				@media (max-width: 460px) {
					height: 130px;
				}
			}

			&:nth-child(2) {
				padding-right: 0px;
			}

			&.unit-videos {
				@media (max-width: 767px) {
					width: 100%;
					padding: 0;
					margin: 0 0 5px;
					min-height: 300px;

					iframe {
						height: 100%;
					}
				}
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				width: 33.3%;
				float: left;
				display: block;
				padding-top: 5px;
			}

			&:nth-child(5) {
				position: relative;
				padding-right: 0px;

				&:after {
					content: "";
					position: absolute;
					left: 0px;
					right: 0px;
					top: 5px;
					bottom: 0px;
					background: rgba(0, 0, 0, 0.5);
					z-index: 0;
				}
			}
		}

		.img-count {
			position: absolute;
			top: 50%;
			bottom: 0;
			text-align: center;
			width: 100%;
			color: #fff;
			font-weight: 600;
			font-size: var(--font-size-md);
			margin-top: -10px;
			z-index: 1;
			padding: 0 10px;
		}

		#matterpotSection+.unit-picture {
			@media (max-width: 767px) {
				width: 100%
			}
		}
	}
}